<template>
  <div class="">
    <v-row
      dense
      justify="space-between"
      align="center"
      class="gradients grey--text text--lighten-2 rounded pa-2 mx-1"
    >
      <span>{{ $t("select currency") }}</span>
      <!-- <AddCurrency @message="showSuccess" @error="showError"/> -->
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :loading="loading"
          no-data-text="No currencies available"
          :items="currencies"
        >
          <template v-slot:[`item.name`]="{ item }">
            <span class="caption">{{ item.name }}</span>
          </template>

          <template v-slot:[`item.label`]="{ item }">
            <span class="caption">{{ item.label }}</span>
          </template>

          <template v-slot:[`item.symbol`]="{ item }">
            <span class="caption">{{ item.symbol }}</span>
          </template>

          <template v-slot:[`item.selected`]="{ item }">
            <v-radio-group v-model="item.selected">
              <v-radio
                :value="checkSelectedStatus(item.id)"
                :color="$store.state.secondaryColor"
                :loading="loading"
                @change="selectedItem(item)"
              ></v-radio>
            </v-radio-group>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-icon
              v-for="button in icons"
              :key="button.icon"
              small
              class="mx-1"
              style="cursor: pointer"
              @click="goTo(button.route, item.id)"
              >{{ button.icon }}</v-icon
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackMessage"
      dark
      color="primary lighten-4 primary--text text--darken-3"
    >
      <v-icon large left color="primary">info</v-icon>
      <span>{{ message }}</span>
      <template v-slot:action="{ attr }">
        <v-btn
          v-bind="attr"
          icon
          color="primary darken-3"
          @click="snackMessage = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="snackerror"
      color="red lighten-4 red--text text--darken-3"
    >
      <v-icon left color="error darken-3">fas fa-bug</v-icon>
      <span>{{ errorMessage }}</span>
      <template v-slot:action="{ attr }">
        <v-btn
          v-bind="attr"
          icon
          color="error darken-3"
          @click="snackerror = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import db from "@/plugins/fb";
// import AddCurrency from '@/components/shop/settings/currency/AddCurrency'

export default {
  components: {
    // AddCurrency,
  },
  data() {
    return {
      dialog: false,
      currencies: [],

      icons: [
        { icon: "edit", route: "edit" },
        { icon: "delete", route: "" },
      ],

      snackMessage: false,
      snackerror: false,
      message: "",
      errorMessage: "",
      loading: true,
      grace: false,

      currencyChecker: [],
    };
  },

  created() {
    this.currentUser = this.$store.state.currentUser;
    this.getCurrencies();
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t("names"),
          value: "name",
          class: "blue-grey darken-3 white--text font-weight-light",
        },
        {
          text: this.$t("label"),
          value: "label",
          class: "blue-grey darken-3 white--text font-weight-light",
        },
        {
          text: this.$t("symbol"),
          value: "symbol",
          class: "blue-grey darken-3 white--text font-weight-light",
        },
        {
          text: this.$t("selection"),
          value: "selected",
          class: "blue-grey darken-3 white--text font-weight-light",
        },
        // { text: 'Action',       value:'action',     class:'blue-grey darken-3 white--text font-weight-light'  },
      ];
    },
  },

  methods: {
    getCurrencies() {
      this.currencies = [];
      db.collection("currencies")
        .get()
        .then((snapshot) => {
          if (snapshot.size > 0) {
            snapshot.forEach((snaps) => {
              this.currencies.push({
                id: snaps.id,
                selected: false,
                ...snaps.data(),
              });

              this.grabCurrencies(snaps.id);
              this.currencies = this.currencies.sort((a, b) =>
                a.name < b.name ? -1 : 1
              );
            });
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch((error) => {
          this.message = error;
          this.loading = false;
          this.snackMessage = true;
        })
        .finally(() => (this.loading = false));
    },

    selectedItem(selectedCurrency) {
      this.loading = true;
      db.collection("configurations")
        .doc(this.currentUser)
        .get()
        .then((snaps) => {
          if (snaps.exists) {
            this.updateConfigCurrency(selectedCurrency);
          } else {
            this.createConfigCurrency(selectedCurrency);
          }
        });
    },

    updateConfigCurrency(selectedCurrency) {
      db.collection("configurations")
        .doc(this.currentUser)
        .update({
          currency: selectedCurrency.id,
        })
        .then(() => {
          this.message = "currency Updated";
          this.loading = false;
          this.snackMessage = true;
          this.getCurrencies();
        })
        .catch((err) => {
          this.message = err;
          this.snackMessage = true;
          this.loading = false;
        });
    },

    createConfigCurrency(selectedCurrency) {
      db.collection("configurations")
        .doc(this.currentUser)
        .set({
          currency: selectedCurrency.id,
          timezone: "",
          paymentMode: "",
        })
        .then(() => {
          this.message = "currency Updated";
          this.loading = false;
          this.snackMessage = true;
          this.getCurrencies();
        })
        .catch((err) => {
          this.message = err;
          this.snackMessage = true;
          this.loading = false;
        });
    },

    grabCurrencies(currencyId) {
      db.collection("configurations")
        .doc(this.currentUser)
        .get()
        .then((snaps) => {
          if (snaps.exists) {
            if (snaps.data().currency == currencyId) {
              var index = this.currencies.findIndex((x) => x.id == currencyId);
              this.currencies[index].selected = true;
              let currencydata;
              db.collection("currencies")
                .doc(currencyId)
                .get()
                .then((doc) => {
                  currencydata = {
                    owner: this.currentUser,
                    id: doc.id,
                    ...doc.data(),
                  };
                  this.$store.commit("SET_CURRENCY", currencydata);
                });
            }
          }
        });
    },

    checkSelectedStatus(status) {
      return status ? true : false;
    },

    showSuccess(event) {
      this.message = event;
      this.snackMessage = true;
      this.getCurrencies();
    },

    showError(event) {
      this.errorMessage = event;
      this.snackerror = true;
    },
  },
};
</script>
